export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'username',
    sortable: true,
    sortField: 'username',
    label: 'field.username',
  },
  {
    key: 'deposit',
    sortable: false,
    sortField: '',
    label: 'field.deposit',
    tdClass: 'text-right',
  },
  {
    key: 'withdraw',
    sortable: false,
    sortField: '',
    label: 'field.withdraw',
    tdClass: 'text-right',
  },
  // {
  //   key: 'remark',
  //   sortable: false,
  //   sortField: '',
  //   label: 'field.remark',
  // },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'authorizer',
    sortable: true,
    sortField: 'authorizer',
    label: 'field.createdBy',
  },
];
